const couponInput = document.querySelector("#coupon_code");

if (couponInput) {
  const parent = couponInput.parentNode;
  const children = parent.childNodes;

  let btn;

  children.forEach((child) => {
    if (child.nodeName === "BUTTON") {
      btn = child;
    }
  });

  couponInput.addEventListener("input", () => {
    if (couponInput.value.length > 0) {
      btn.classList.add("couponFilled");
    } else {
      btn.classList.remove("couponFilled");
    }
  });
}
