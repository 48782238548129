const rodoCheckbox = document.querySelector("#rodo-register");

if (rodoCheckbox) {
  const rodoBox = document.querySelector(".woocommerce-privacy-policy-text");
  const rodoBtn = document.querySelector(".woocommerce-form-register__submit");
  const rodoText = rodoBox.childNodes[0];

  if (rodoBox && rodoBtn) {
    rodoBox.appendChild(rodoCheckbox);
    rodoBtn.setAttribute("type", "button");

    rodoBox.addEventListener("change", (e) => {
      if (e.target.checked === true) {
        rodoBtn.setAttribute("type", "submit");
        rodoText.classList.remove("rodo-error");
      } else {
        rodoBtn.setAttribute("type", "button");
        rodoText.classList.add("rodo-error");
      }
    });

    rodoBtn.addEventListener("click", () => {
      if (rodoBtn.type === "button") {
        rodoText.classList.add("rodo-error");
      } else {
        rodoText.classList.remove("rodo-error");
      }
    });
  }
}
